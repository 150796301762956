import { createUseStyles } from "react-jss";

export default createUseStyles({
    errorScren: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    containerTitle: {
        position: "relative",
        lineHeight: "25px"
    },
    title: {
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: "186px",
        fontWeight: "900",
        margin: "0px",
        textTransform: "uppercase",
    },
    oops: {
        fontFamily: "'Titillium Web', sans-serif",
        fontSize: "26px",
        fontWeight: "700",
        margin: "0",
    },
    sorry: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "14px",
        fontWeight: "500",
        marginBottom: "0px",
        textRransform: "uppercase"
    },
    homepage: {
        fontFamily: "'Titillium Web', sans-serif",
        display: "inline-block",
        textTransform: "uppercase",
        color: "#fff",
        textDecoration: "none",
        border: "none",
        background: "#5c91fe",
        padding: "10px 40px",
        fontSize: "14px",
        fontWeight: "700",
        borderRadius: "1px",
        marginTop: "15px",
        transition: "0.2s all"
    }
})