import { envProjectName, envBaseUrl } from 'src/shared/utils/Environment'

export const projectName: string = envProjectName;
export const projectNameSmall: string = "QRP";

export const baseUrl: string = envBaseUrl;

export const dateFormat: string = "DD MMM YYYY";

export const baseApiUrl: string = `${baseUrl}api/v1.0/`;

export const qrCodeUrl: string = envBaseUrl;

export const mediaQueryForPhone: string = "@media (max-width: 768px)";