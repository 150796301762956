import React, { memo, useEffect } from 'react';
import EventListener from 'src/shared/services/EventListener';

const LoadingSuspence: React.FC<{}> = memo(() => {
    useEffect(() => {
        EventListener.emit('loading', true);
        return () => {
            EventListener.emit('loading', false);
        }
    }, []);

    return null;
});

export default LoadingSuspence;