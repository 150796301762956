import React, { memo, Suspense } from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from 'react-tooltip';

import Loading from 'src/layout/loading/Loading';
import Container from 'src/layout/container/Container';
import Header from 'src/layout/header/Header';
import Body from 'src/layout/body/Body';
import Footer from 'src/layout/footer/Footer';
import LoadingSuspence from 'src/layout/loading/LoadingSuspence';

import { setTitle } from 'src/shared/constants/Functions';

import 'src/shared/translations/i18n';

import ErrorBoundary from './ErrorBoundary';
import Router from './Router';

type Props = {
}

setTitle("");

const App: React.FC<Props> = memo(() => (
    <ErrorBoundary>
        <Suspense fallback={<LoadingSuspence />}>
            <Container>
                <Header />
                <Body>
                    <Router />
                </Body>
                <Footer />
            </Container>
        </Suspense>
        <ToastContainer />
        <Loading />
        <ReactTooltip place="top" effect="solid"/>
    </ErrorBoundary>
));

export default App;
