export class ParamUtils {
    public static format(params: { [key: string]: any }): URLSearchParams {
        const httpParams = new URLSearchParams();
         Object.keys(params).forEach((key: string) => {
            let value = params[key];

            if ( value ) {
                if ( Array.isArray(value) ) {
                    value.forEach((val: any) => {
                        if ( val ) {
                            httpParams.append(key, val);
                        }
                    });
                    return;
                }
                else {

                    if (value instanceof Date) {
                        value = value.toISOString();
                    }
    
                    httpParams.set(key, value);

                }

            }
        });

        return httpParams;
    }
}