import React, { memo } from 'react';

type Props = {
}

const Footer: React.FC<Props> = memo(() => {
    return (
        null
    )
});

export default Footer;