import { toast, ToastOptions } from 'react-toastify';
import i18n from 'i18next';
import { HttpError, ErrorMessages } from 'src/shared/constants/Errors';

const toastConfig: ToastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

class ToastService {
    public info(message: string) {
        toast.info(i18n.t(message), toastConfig)
    }
    
    public warning(message: string) {
        toast.warning(i18n.t(message), toastConfig)
    }

    public error(message: string) {
        toast.error(i18n.t(message), toastConfig)
    }

    public success(message: string) {
        toast.success(i18n.t(message), toastConfig)
    }

    public errorMessage = (httpError?: HttpError | null) => {
        const defaultMessage: string = 'toast.unexpected_error';
        let errorMessage: string = defaultMessage;
        if ( httpError ) {
            switch(httpError.statusCode) {
                case 205:
                break;
                case 409:
                break;
                case 400:
                    const msg: string = ErrorMessages[httpError.error];
                    errorMessage = msg ? msg : (httpError.error ? httpError.error : errorMessage);
                break;
            }
        }
        this.error(errorMessage);
    }
}

export default new ToastService();