import { createUseStyles } from "react-jss";

import { horizontalPadding } from 'src/shared/constants/styles';
import { mediaQueryForPhone } from 'src/Config';

const headerPadding: string = `1rem ${horizontalPadding}`;

export default createUseStyles({
    mainHeader: {
        display: "flex",
        padding: headerPadding,
        flexFlow: "row nowrap",
        borderBottom: "1px solid #dee2e6",
        alignItems: "center",
        [mediaQueryForPhone]: {
            alignItems: "flex-start",
            flexDirection: "column"
        }
    },
    headerProjectName: {
        marginRight: ".5rem",
        fontSize: "1.25rem",
        lineHeight: "inherit",
        whiteSpace: "nowrap",
        color: "rgba(0,0,0,.9)",
        display: "flex",
        alignItems: "center"
    },
    headerName: {
        fontWeight: "600",
        overflowWrap: "break-word",
        wordBreak: "break-word",
        width: "7rem",
        whiteSpace: "break-spaces",
        marginLeft: "0.758rem"
    },
    headerIconContainer: {
        boxShadow: "0 7px 20px rgba(0,0,0,.19), 0 6px 6px rgba(0,0,0,.23)",
        marginTop: "-.5rem",
        marginRight: ".2rem",
        borderRadius: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0.75rem"
    }
})