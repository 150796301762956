import React, { lazy, memo, Suspense } from 'react';
import {
    Switch,
    Route,
    Router as BrowserRouter
} from "react-router-dom";
import { createBrowserHistory } from 'history';

import LoadingSuspence from 'src/layout/loading/LoadingSuspence';
// https://stackoverflow.com/questions/23561370/download-and-install-an-ipa-from-self-hosted-url-on-ios
const ProjectsScreen = lazy(() => import('src/pages/projects/ProjectsScreen'));
const VersionScreen = lazy(() => import('src/pages/version/VersionScreen'));
const VersionsScreen = lazy(() => import('src/pages/versions/VersionsScreen'));
const NotFoundScreen = lazy(() => import('src/pages/notFound/NotFoundScreen'));
const InstallScreen = lazy(() => import('src/pages/install/InstallScreen'));

export const history = createBrowserHistory();

const Router: React.FC<{}> = memo(() => (
    <BrowserRouter history={history}>
        <Suspense fallback={<LoadingSuspence />}>
            <Switch>
                <Route exact path="/" component={ProjectsScreen} />
                <Route path="/versions/:projectId" component={VersionsScreen} />
                <Route path="/version/:versionId" component={VersionScreen} />
                <Route path="/install/:url" component={InstallScreen} />
                <Route path="/notfound" component={NotFoundScreen} />
                <Route path="*" component={NotFoundScreen} />
            </Switch>
        </Suspense>
    </BrowserRouter>
));

export default Router;