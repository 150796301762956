type ProcessEnv = {
    NODE_ENV: 'development' | 'test' | 'production';
    // All inserted variables have to start with REACT_APP_
    REACT_APP_BASE_URL: string;
    REACT_APP_APP_NAME: string;
}

const env: ProcessEnv = process.env as unknown as ProcessEnv;

export const isProduction : boolean = env.NODE_ENV == 'production';
export const isDevelopment : boolean = env.NODE_ENV == 'development';
export const isTest : boolean = env.NODE_ENV == 'test';
export const envBaseUrl: string = env.REACT_APP_BASE_URL;
export const envProjectName: string = env.REACT_APP_APP_NAME;

