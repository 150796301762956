import React, { memo } from 'react';
import QrCode from 'qrcode.react';

import Search from 'src/components/search/Search';

import useStyles from './Header.styles';
import { projectName, qrCodeUrl } from 'src/Config';

const Header: React.FC<{}> = memo(() => {
    const styles = useStyles();
    return (
        <header className={styles.mainHeader}>
            <a href="/" className={styles.headerProjectName}>
                <div className={styles.headerIconContainer}>
                    <QrCode value={qrCodeUrl} size={50}/>
                </div>
                <div className={styles.headerName}>{projectName}</div>
            </a>
            <Search />
        </header>
    )
});

export default Header;
