import React, { memo } from 'react';

import useStyles from './SuggestionLabel.style';

export type LabelType = 'PROJECT' | 'VERSION' | 'VERSION ATTACHMENTID' | 'URL'

type Props = {
    type: LabelType
    url?: string;
    value?: string
}

enum TypeColos {
    'PROJECT' = "#f39c12",
    'VERSION' = "#00c0ef",
    'VERSION ATTACHMENTID' = "#dd4b39",
    'URL' = "#605ca8"
}

const SuggestionLabel: React.FC<Props> = memo(({ type, url, value }: Props) => {
    const styles = useStyles({
        backgroundColor: TypeColos[type]
    });

    return (
        <a 
            href={url}
            target="_blanck"
            rel="noopener noreferrer"
            className={`${styles.label} ${url ? ` ${styles.labelHover}` : ''}`} 
        >
            { value }
        </a>
    )
});

export default SuggestionLabel;