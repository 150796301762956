import { createUseStyles } from "react-jss";
import { mediaQueryForPhone } from "src/Config";
import { bodyBackgroundColor, horizontalPadding } from "src/shared/constants/styles";

export default createUseStyles({
    contentWrapper: {
        backgroundColor: bodyBackgroundColor,
        flex: 1,
        height: '100%',
        padding: `1rem ${horizontalPadding}`,
        display: "flex",
        flexDirection: "column",
        [mediaQueryForPhone]: {
            padding: `1rem 1rem 0`
        }
    }
})