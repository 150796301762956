import React, { memo } from 'react';

import useStyles from './Body.styles';

type Props = {
    children?: React.ReactNode;
}

const Body: React.FC<Props> = memo(({ children }: Props) => {
    const styles = useStyles();
    return (
        <div className={styles.contentWrapper}>
            { children }
        </div>
    )
});

export default Body;