
type EventListenerType = 'loading';
type EventListenerValues = boolean;

export type EventListenerCb = (value: EventListenerValues) => void

export type EventListenerSubscription = {
    removeEventListener(): void;
}

class EventListener {
    private events: { [eventName: string]: Array<EventListenerCb> } = { };

    public addListener(eventName: 'loading', cb: (loading: boolean) => void): EventListenerSubscription;
    public addListener(eventName: EventListenerType, cb: EventListenerCb): EventListenerSubscription {
        if ( !this.events[eventName] ) {
            this.events[eventName] = [];
        }
        const index: number = this.events[eventName].length;

        this.events[eventName].push(cb);
        
        return {
            removeEventListener: () => {
                this.events[eventName].splice(index, 1);
            }
        };
    }
    
    public emit(eventName: 'loading', loading: boolean): void;
    public emit(eventName: EventListenerType, values: EventListenerValues): void {
        if ( this.events[eventName] && this.events[eventName].length ) {
            this.events[eventName].forEach((cb: EventListenerCb) => {
                if ( cb ) {
                    cb(values);
                }
            })
        }
    }
}
export default new EventListener();