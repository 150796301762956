import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { StatusCodes } from "http-status-codes";

import { baseApiUrl } from 'src/Config';
import { history } from "src/Router";
import ToastService from 'src/shared/services/ToastService';

import { HttpError } from "../constants/Errors";
import { ParamUtils } from "../utils/ParamUtils";

import EventListener from "./EventListener";

const config: AxiosRequestConfig = {
    baseURL: baseApiUrl
} 

class Http {
    protected http: AxiosInstance;

    constructor() {
        this.http = Axios.create(config);

        this.http.interceptors.request.use(
            (request: AxiosRequestConfig) => {
                //request.headers = this.getHeaders(request.headers);
                EventListener.emit('loading', true);
                return request;
            }, 
            (error: AxiosError) => error
        );

        this.http.interceptors.response.use(
            (response: AxiosResponse) => {
                EventListener.emit('loading', false);
                return response.data;
            }, 
            (error: any) => {
                EventListener.emit('loading', false);
                const httpError: HttpError | null = HttpError.error(error);
                if ( httpError?.statusCode == StatusCodes.NOT_FOUND ) {
                    history.push("/notfound");
                    return;
                }
                try {
                    
                }
                catch(ex) {
                    //Logger.error("APP_CRASH", ex);
                }
                finally {
                    ToastService.errorMessage(httpError);
                    return Promise.reject(httpError);
                }
            }
        );
    }

    public get<T>(url: string): Promise<T>
    public get<T>(url: string, params: any): Promise<T>;
    public get<T, K extends object>(url: string, params: K): Promise<T>;
    public get<T, K extends object>(url: string, params?: K): Promise<T> {
        let paramsObject: Object = { };
        if ( params ) {
            paramsObject = ParamUtils.format(params);
        }
        return this.http.get(url, { params: paramsObject });
    }
    
    public post<T, K = any>(url: string, data?: K): Promise<T> {
        return this.http.post(url, data);
    }
    
    public put<T, K = any>(url: string, data?: K): Promise<T> {
        return this.http.put(url, data);
    }
    
    public delete<T, K = any>(url: string, data?: K): Promise<T> {
        return this.http.delete(url, data);
    }

    public upload<T, K = any>(method: 'POST' | 'PUT', url: string, model: K, files: Array<File>): Promise<T> {
        const formData = new FormData();
        if ( files && files.length ) {
            files.forEach((file: File) => {
                formData.append('files', file);
            });
        }

        formData.append("model", JSON.stringify(model));

        return this.http.request({
            method,
            url,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
}
export default new Http();