import React, { memo, useCallback } from 'react';
import { FileUtils } from 'src/shared/utils/FileUtils';

import { SearchDto } from '../../interface/SearchDto';
import SuggestionLabel, { LabelType } from '../suggestionLabel/SuggestionLabel';

import useStyles from './Suggestion.style';

type Props = {
    suggestion: SearchDto
}

const Suggestion: React.FC<Props> = memo(({ suggestion }: Props) => {
    const styles = useStyles();

    const Label = useCallback((type: LabelType, value?: string, url?: string) => {
        if ( value ) {
            return (
                <SuggestionLabel
                    type={type}
                    url={url} 
                    value={value}
                />
            )
        }
        return ""
    }, [])

    return (
        <div className={styles.suggestion}>
            { Label('PROJECT', suggestion.projectName, `/versions/${suggestion.projectId}`) }
            { suggestion.versionId ? Label('VERSION', suggestion.versionName, `/version/${suggestion.versionId}`) : null }
            { 
                suggestion.attachmentId && suggestion.fileName ? 
                    Label('VERSION ATTACHMENTID', suggestion.fileName, FileUtils.getUrl(suggestion.attachmentId, suggestion.fileName))
                : ""
            }
            { 
                suggestion.url ? 
                    Label('URL', suggestion.fileName, FileUtils.getQRCode(suggestion.url)) 
                : null
            }
        </div>
    )
});

export default Suggestion;