import React, { PureComponent } from 'react';

import ErrorScreen from 'src/pages/errorScreen/ErrorScreen';
import { isDevelopment } from './shared/utils/Environment';


type Props = {
}

type State = {
    hasError: boolean
}

export default class ErrorBoundary extends PureComponent<Props, State> {

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    state: State = {
        hasError: false
    }
    
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        if ( isDevelopment ) {
            console.log("error", error)
            console.log("errorInfo", errorInfo)
        }
        // You can also log the error to an error reporting service
    }

    render() {
        if ( this.state.hasError ) {
            // You can render any custom fallback UI
            return ( <ErrorScreen/> );
        }

        return this.props.children;
    }
}