import { createUseStyles } from "react-jss";

export default createUseStyles({
    content: {
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "100%",
        minHeight: "100vh"
    }
})