import React, { memo } from 'react';
import useStyles from './InputGroup.styles';

type Props = {
    className?: string;
    children: JSX.Element;
    icon: JSX.Element
}

const InputGroup: React.FC<Props> = memo(({ className, children, icon }: Props) => {
    const styles = useStyles();
    const iconElement = React.cloneElement(icon, { className: styles.icon });

    return (
        <div className={`${styles.inputGroup}${className ? ` ${className}` : ''}`}>
            { children }
            <div className={styles.inputGroupContainer}>
                { iconElement }
            </div>
        </div>
    )
});

export default InputGroup;