import React, { memo, useCallback, useReducer, useRef } from 'react';
import { FaSearch } from 'react-icons/fa';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';

import Autosuggest, { ShouldRenderReasons, SuggestionsFetchRequestedParams, RenderSuggestionsContainerParams } from 'react-autosuggest';

import { UseReducer } from 'src/shared/interfaces/UseState';
import { reducer } from 'src/shared/constants/Functions';

import InputGroup from '../inputGroup/InputGroup';

import Suggestion from './components/suggestion/Suggestion';
import SuggestionContainer from './components/suggestionContainer/SuggestionContainer';

import { SearchDto } from './interface/SearchDto';

import SearchService from './SearchService';
import useStyles from './Search.styles';

type State = {
    search: string;
    items: Array<SearchDto>;
}

const getSuggestionValue = (suggestion: SearchDto) => suggestion.projectName;

const renderSuggestion = (suggestion: SearchDto) => (
    <Suggestion suggestion={suggestion}/>
);

const needNumberOfCharacters: number = 3;

const Search: React.FC<{ }> = memo(() => {
    const styles = useStyles();
    const { t } = useTranslation();
    const [{ search, items }, setState]: UseReducer<State> = useReducer(
        reducer<State>(),
        {
            search: "",
            items: []
        }
    );

    const debouncedLoadSuggestions = useRef(
        debounce(({ value,  reason }: SuggestionsFetchRequestedParams) => {
            if ( reason == 'input-changed' ) {
                SearchService.search({ search: value })
                .then((items: Array<SearchDto>) => {
                    setState({ items })
                })
            }
        }, 500)
    ).current;

    const onSuggestionsFetchRequested = useCallback((fetch: SuggestionsFetchRequestedParams) => {
        debouncedLoadSuggestions(fetch)
    }, [debouncedLoadSuggestions]);

    const onSuggestionsClearRequested = useCallback(() => {
        setState({ items: [] })
    }, []);

    const shouldRenderSuggestions = useCallback((value: string, reason: ShouldRenderReasons) => {
        return Boolean((reason == 'render' || reason == 'input-changed') && value && value.length >= needNumberOfCharacters);
    }, []);

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setState({ search: e.target.value });
    }, []);

    const renderSuggestionsContainer = useCallback(
        ({ children, containerProps: { ref, className, role, key } }: RenderSuggestionsContainerParams) => (
            <SuggestionContainer 
                key={key}
                forwaderRef={ref} 
                className={className}
                role={role}
            >
                { children }
            </SuggestionContainer>
        ), []);


    const inputProps = {
        placeholder: t('search.placeholder'),
        value: search || "",
        onChange: onChange
    };
        
    return (
        <InputGroup
            className={styles.search}
            icon={<FaSearch />}
        >
            <Autosuggest
                theme={styles}
                suggestions={items}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                shouldRenderSuggestions={shouldRenderSuggestions}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                renderSuggestionsContainer={renderSuggestionsContainer}
            />
        </InputGroup>
    )
});

export default Search;