import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import SuggestionLabel, { LabelType } from '../suggestionLabel/SuggestionLabel';

import useStyles from './SuggestionContainer.style';

type Props = {
    forwaderRef: any;
    role: string;
    className: string;
    children: React.ReactNode
}

const labelTypes: Array<LabelType> = [
    "PROJECT",
    "VERSION",
    "VERSION ATTACHMENTID",
    "URL"
]

const SuggestionContainer: React.FC<Props> = memo(({ 
    forwaderRef, role, className, children 
}: Props) => {
    const styles = useStyles({});
    const { t } = useTranslation();

    return (
        <div ref={forwaderRef} role={role} className={className}>
            {
                className ? 
                    <div className={styles.suggestionContainer}>
                        {
                            labelTypes.map((labelType: LabelType) => (
                                <SuggestionLabel
                                    type={labelType}
                                    value={t('suggestion_container.'+ labelType.toLowerCase())}
                                />
                            ))
                        }
                    </div>
                : null
            }
            { children }
        </div>
    )
});

export default SuggestionContainer;