import React, { memo } from 'react';

import useStyles from './Container.styles';

type Props = {
    children: React.ReactNode;
}

const Container: React.FC<Props> = memo(({ children }: Props) => {
    const styles = useStyles();
    return (
        <main className={styles.content}>
            { children }
        </main>
    )
});

export default Container;