import i18n, { InitOptions } from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';

import { DEFAULT_LANGUAGE, LANGUAGES, defaultNS } from "../constants/Languages";

const userLang: string = navigator.language || (navigator as any).userLanguage; 

export const languageDefault: string = (LANGUAGES as any)[userLang] ||  DEFAULT_LANGUAGE;


const options: InitOptions = {
    fallbackLng: languageDefault,
    backend: {
        loadPath: '/locales/{{lng}}.json',
    },
    
    load: "languageOnly",
    lng: "en",
    ns: [defaultNS],
    defaultNS: defaultNS,

    interpolation: {
        escapeValue: false // react already safes from xss
    }
}

i18n
.use(HttpApi)
.use(initReactI18next) // passes i18n down to react-i18next
.use(LanguageDetector)
.init(options);

export default i18n;