import { createUseStyles } from "react-jss";

const backgroundColor: string = "#f2f4f6";
const textColor: string = "#495057"

export default createUseStyles({
    inputGroup: {
        display: "flex",
        flexWrap: "wrap",
        flexFlow: "row nowrap"
    },
    /*inputElement: {
        fontWeight: "400",
        color: textColor,
        backgroundClip: "padding-box",
        boxShadow: "inset 0 0 0 transparent",
        padding: ".25rem .5rem",
        fontSize: ".875rem",
        lineHeight: "1.5",
        borderRadius: ".2rem",
        height: "calc(1.8125rem + 2px)",
        backgroundColor: backgroundColor,
        border: "0",
        borderTopRightRadius: "0",
        borderBottomRightRadius: "0",
        transition: "none",
        outline: "none"
    },*/
    inputGroupContainer: {
        padding: ".25rem .5rem",
        fontSize: ".875rem",
        lineHeight: "1.5",
        borderRadius: ".2rem",
        display: "flex",
        backgroundColor: backgroundColor,
        alignItems: "center"
    },
    icon: {
        color: textColor
    }
})