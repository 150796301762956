import { createUseStyles } from "react-jss";

export default createUseStyles({
    label: {
        padding: ".5rem .9rem",
        fontSize: "75%",
        fontWeight: "700",
        lineHeight: "1",
        textAlign: "center",
        whiteSpace: "nowrap",
        borderRadius: "5px",
        backgroundColor: props => props.backgroundColor,
        color: "white",
        display: "block",
        "&:not(:last-child)": {
            marginRight: "0.5rem"
        }
    },
    labelHover: {
        cursor: "pointer",
        "&:hover": {
            opacity: 0.5
        }
    }
})