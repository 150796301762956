import React, { memo, useEffect } from 'react';

import { setTitle } from 'src/shared/constants/Functions';

import useStyles from './ErrorScreen.style';

const ErrorScreen: React.FC<{ }> = memo(() => {
    const styles = useStyles();
    useEffect(() => {
        setTitle("Something went wrong");
    }, [])

    return (
        <div className={styles.errorScren}>
            <div className="notfound">
                <div>
                    <h1 className={styles.title}>404</h1>
                </div>
                <h2 className={styles.oops}>Oops! This Page Could Not Be Found</h2>
                <p className={styles.sorry}>Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily unavailable</p>
                <a className={styles.homepage} href="/">Go To Homepage</a>
            </div>
        </div>    
    )
});

export default ErrorScreen;