import React, { PureComponent } from 'react';

import EventListener, { EventListenerSubscription } from "src/shared/services/EventListener";

import useStyles from "./Loading.styles";

type Props = {
}

type State = {
    loading: boolean
}

type PropsLoadingContainer = {
    children: React.ReactNode
}

const LoadingContainer: React.FC<PropsLoadingContainer> = ({ children }: PropsLoadingContainer) => {
    const styles = useStyles();

    return (
        <div aria-busy="true" className={styles.loadingContainer}>
            { children }
        </div>
    )
};

export default class Loading extends PureComponent<Props, State> {
    private event: EventListenerSubscription | null = null;

    state: State = {
        loading: false
    }

    componentDidMount() {
        const { loading }: State = this.state;
        this.event = EventListener.addListener('loading', (_loading: boolean) => {
            if ( _loading !== loading || loading == false ) {
                this.setState({ loading: _loading });
            }
        });
    }

    componentWillUnmount() {
        if ( this.event ) {
            this.event.removeEventListener();
        }
    }

    render() {
        const { loading }: State = this.state;
        if ( !loading ) {
            return null;
        }
        return (
            <LoadingContainer>
                <svg width="100" height="100" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#00BFFF">
                    <g fill="none" fillRule="evenodd" strokeWidth="2">
                        <circle cx="22" cy="22" r="1">
                            <animate attributeName="r" begin="0s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"></animate>
                            <animate attributeName="strokeOpacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"></animate>
                        </circle>
                        <circle cx="22" cy="22" r="1">
                            <animate attributeName="r" begin="-0.9s" dur="1.8s" values="1; 20" calcMode="spline" keyTimes="0; 1" keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"></animate>
                            <animate attributeName="strokeOpacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline" keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"></animate>
                        </circle>
                    </g>
                </svg>
            </LoadingContainer>
        )
    }
}
