import Http from 'src/shared/services/HttpService';

import { SearchCriteria } from './interface/SearchCriteria';
import { SearchDto } from './interface/SearchDto';

class SearchService {

    public search(criteria: SearchCriteria): Promise<Array<SearchDto>> {
        return Http.get<Array<SearchDto>, SearchCriteria>(`search/`, criteria)
    }
}

export default new SearchService();