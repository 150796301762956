import { createUseStyles } from "react-jss";
import { mediaQueryForPhone } from "src/Config";

const inputWidth: string = "25rem";

const inputHeight: number = 40;
const inputHeightExt: string = "px";

export default createUseStyles({
    search: {
        height: "100%",
        padding: "11px 0",
        [mediaQueryForPhone]: {
            minWidth: "100%",
            position: "relative"
        }
    },
    container: {
        minWidth: inputWidth,
        position: "absolute",
        zIndex: "2",
        [mediaQueryForPhone]: {
            minWidth: "100%"
        }
    },
    input: {
        width: "100%",
        height: `${inputHeight}${inputHeightExt}`,
        padding: "10px 20px",
        fontFamily: "Helvetica, sans-serif",
        fontWeight: "300",
        fontSize: "16px",
        border: "1px solid #aaa",
        borderRadius: "4px"
    },
    inputFocused: {
        outline: "none"
    },
    inputOpen: {
        borderBottomLeftRadius: "0",
        borderBottomRightRadius: "0"
    },
    suggestionContainer: {
        display: "none"
    },
    suggestionsContainerOpen: {
        display: "block",
        marginTop: -1,
        minWidth: inputWidth,
        maxWidth: "35vw",
        border: "1px solid #aaa",
        backgroundColor: "#fff",
        fontFamily: "Helvetica, sans-serif",
        fontWeight: "300",
        fontSize: "16px",
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
        [mediaQueryForPhone]: {
            minWidth: "100%"
        }
    },
    suggestionsList: {
        margin: "0",
        padding: "0",
        listStyleType: "none"
    },
    suggestion: {
        padding: "10px 20px",
        height: "100%",
        ":not(:first-child)": {
            borderTop: "1px solid #ddd",
        }
    },
    suggestionFocused: {
        backgroundColor: "#0C7EAF",
        color: "#fff"
    },
    suggestionContent: {
        display: "flex",
        alignItems: "center",
        backgroundRepeat: "no-repeat"
    }
})