import { lookup } from 'mime-types';
import { isIOS } from "react-device-detect";

import { baseUrl } from "src/Config";

const urlFile: string = `${baseUrl}file/`

export class FileUtils {
    public static getUrlByFileName(id: string, fileName: string) {
        return FileUtils.getUrl(id, lookup(fileName) || "");
    }

    public static getUrl(id: string, mimeType: string) {
        if ( FileUtils.isImage(mimeType) ) {
            return FileUtils.getImageUrl(id);
        }
        return FileUtils.getFileUrl(id);
    }

    public static getImageUrl(id: string) {
        return `${urlFile}${id}.png?width=100%&height=100%&format=jpg`;
    }
    
    public static getFileUrl(id: string) {
        return `${urlFile}${id}`;
    }

    public static getIPAFileUrl(id: string) {
        return `${FileUtils.getFileUrl(id)}.ipa`;
    }

    public static getApkFileUrl(id: string) {
        return `${FileUtils.getFileUrl(id)}.apk`;
    }

    public static getQRCode(url: string): string {
        return `${baseUrl}qrcode/${url}`;
    }

    public static getManifestUrl(url: string): string {
        if ( isIOS ) {
            return `itms-services://?action=download-manifest&url=${urlFile}${url}/manifest.plist`;
        }
        return FileUtils.getIPAFileUrl(url);
    }

    public static isImage(mimeType: string): boolean {
        return mimeType.includes('image');
    }
}