import { createUseStyles } from "react-jss";
import { mediaQueryForPhone } from "src/Config";

const mobileMarginRight: string = "0.5rem";

export default createUseStyles({
    suggestionContainer: {
        width: "100%",
        padding: "10px 20px",
        display: "flex",
        flexWrap: "wrap",
        borderBottom: "1px solid #aaa",
        "& > *": {
            flex: 1,
            [mediaQueryForPhone]: {
                marginRight: mobileMarginRight,
                flex: `0 0 calc(50% - ${mobileMarginRight})`,
                "&:not(:nth-child(-n+2))": {
                    marginTop: mobileMarginRight,
                }
            }
        }
    }
})