import { createUseStyles } from "react-jss";
import { mediaQueryForPhone } from "src/Config";

const mobileMarginRight: string = "0.5rem";

export default createUseStyles({
    suggestion: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "flex",
        flexWrap: "wrap",
        [mediaQueryForPhone]: {
            "& > *": {
                marginRight: mobileMarginRight,
                flex: `0 0 calc(50% - ${mobileMarginRight})`,
                "&:not(:nth-child(-n+2))": {
                    marginTop: mobileMarginRight,
                }
            }
        }
    }
})