
export class HttpError {
    static error(error: any): HttpError | null {
        if ( error ) {
            return new HttpError(error?.message, error?.response?.status, error?.response?.data, error?.response?.statusText)
        }
        return null;
    }

    constructor(
        public message: string = "", 
        public statusCode: number = 0, 
        public error: any = null, 
        public statusText: string = ""
    ) { }
}

export enum NotFoundCode {
    NOT_FOUND = "NOT_FOUND"
}

export enum ErrorMessages {
    
}